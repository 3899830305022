<template>
    <div class="news_inner dirr">
        <v-layout row>
            <v-flex sm12 xs12 md9 lg9 style="padding:0 20px;" v-if="MainNews_skeleton">
                <div class="mainNews">
                    <br /><br />
                    <v-skeleton-loader  width="800" min-width="800" type="image"></v-skeleton-loader>
                    <v-skeleton-loader  width="800" min-width="800" type="card"></v-skeleton-loader>
                    <v-skeleton-loader  width="800" min-width="800" type="paragraph, paragraph, paragraph, sentences, paragraph, sentences paragraph, sentences paragraph, sentences"></v-skeleton-loader>
                </div>
            </v-flex>
            <v-flex sm12 xs12 md9 lg9 style="padding:0 20px;" v-else>
                <div class="mainNews">
                    <p class="tl submain" v-if="mainNews[0].title2" v-html="mainNews[0].title2"></p>
                    <p class="tl main" v-html="mainNews[0].title1"></p>
                    <div class="details">
                        <div class="division" v-if="mainNews[0].cat && $i18n.locale=='ar'">
                            <span>
                            <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M10.1265 0.680164L6.0592 4.77808L6.73634 5.46032L10.8036 1.36241L10.1265 0.680164Z" fill="#B1BD52"/>
                                <path d="M10.1302 3.40876L7.41504 6.14441L8.09218 6.82665L10.8074 4.091L10.1302 3.40876Z" fill="#B1BD52"/>
                                <path d="M14.1926 4.7787L13.5155 5.46094L14.1926 6.14318L14.8698 5.46094L14.1926 4.7787Z" fill="#B1BD52"/>
                                <path d="M11.4843 4.77402L8.77136 7.50745L9.4485 8.18969L12.1615 5.45626L11.4843 4.77402Z" fill="#B1BD52"/>
                                <path d="M11.4855 7.5066L10.1268 8.87555L10.804 9.55779L12.1627 8.18885L11.4855 7.5066Z" fill="#B1BD52"/>
                                <path d="M10.1303 3.41039L13.516 6.82159L14.1931 6.13935L10.8074 2.72815L10.1303 3.41039Z" fill="#B1BD52"/>
                                <path d="M12.7116 3.28386L14.8713 5.4599L15.5485 4.77766L13.3887 2.60162L12.7116 3.28386Z" fill="#B1BD52"/>
                                <path d="M10.1306 6.14035L12.162 8.18707L12.8391 7.50483L10.8077 5.4581L10.1306 6.14035Z" fill="#B1BD52"/>
                                <path d="M6.06551 4.77829L8.09692 6.82501L8.77406 6.14277L6.74265 4.09604L6.06551 4.77829Z" fill="#B1BD52"/>
                                <path d="M10.1302 0.682227L12.0355 2.60187L12.7127 1.91962L10.8074 -1.60074e-05L10.1302 0.682227Z" fill="#B1BD52"/>
                                <path d="M1.31787 10.922L5.38513 15.02L6.06227 14.3377L1.99501 10.2398L1.31787 10.922Z" fill="#B1BD52"/>
                                <path d="M4.02626 10.9169L6.74146 13.6525L7.4186 12.9703L4.7034 10.2346L4.02626 10.9169Z" fill="#B1BD52"/>
                                <path d="M5.38695 6.82478L6.06409 7.50702L6.74122 6.82478L6.06409 6.14254L5.38695 6.82478Z" fill="#B1BD52"/>
                                <path d="M5.38443 9.55051L8.09741 12.2839L8.77455 11.6017L6.06157 8.86827L5.38443 9.55051Z" fill="#B1BD52"/>
                                <path d="M8.09406 9.55098L9.45276 10.9199L10.1299 10.2377L8.77119 8.86874L8.09406 9.55098Z" fill="#B1BD52"/>
                                <path d="M4.02739 10.9174L7.41309 7.50623L6.73595 6.82398L3.35025 10.2352L4.02739 10.9174Z" fill="#B1BD52"/>
                                <path d="M3.90481 8.31648L6.06458 6.14044L5.38744 5.4582L3.22768 7.63424L3.90481 8.31648Z" fill="#B1BD52"/>
                                <path d="M6.73763 10.9159L8.76904 8.86914L8.09191 8.1869L6.06049 10.2336L6.73763 10.9159Z" fill="#B1BD52"/>
                                <path d="M5.38525 15.0154L7.29053 13.0958L6.61339 12.4135L4.70811 14.3332L5.38525 15.0154Z" fill="#B1BD52"/>
                                <path d="M1.32104 10.9203L3.22632 9.00067L2.54918 8.31843L0.643898 10.2381L1.32104 10.9203Z" fill="#B1BD52"/>
                                <path d="M11.483 19.8005L15.5503 15.7026L14.8732 15.0203L10.8059 19.1182L11.483 19.8005Z" fill="#B1BD52"/>
                                <path d="M11.4801 17.0709L14.1953 14.3353L13.5182 13.653L10.803 16.3887L11.4801 17.0709Z" fill="#B1BD52"/>
                                <path d="M7.41979 15.7016L8.09692 15.0193L7.41979 14.3371L6.74265 15.0193L7.41979 15.7016Z" fill="#B1BD52"/>
                                <path d="M10.125 15.7044L12.838 12.971L12.1609 12.2888L9.44789 15.0222L10.125 15.7044Z" fill="#B1BD52"/>
                                <path d="M10.1259 12.9753L11.4846 11.6064L10.8075 10.9241L9.44878 12.2931L10.1259 12.9753Z" fill="#B1BD52"/>
                                <path d="M11.4802 17.0687L8.09448 13.6575L7.41734 14.3397L10.803 17.7509L11.4802 17.0687Z" fill="#B1BD52"/>
                                <path d="M8.89975 17.1965L6.73999 15.0204L6.06285 15.7027L8.22261 17.8787L8.89975 17.1965Z" fill="#B1BD52"/>
                                <path d="M11.4783 14.3398L9.4469 12.2931L8.76976 12.9753L10.8012 15.0221L11.4783 14.3398Z" fill="#B1BD52"/>
                                <path d="M15.5472 15.7023L13.642 13.7827L12.9648 14.4649L14.8701 16.3845L15.5472 15.7023Z" fill="#B1BD52"/>
                                <path d="M11.4821 19.7978L9.57678 17.8781L8.89964 18.5604L10.8049 20.48L11.4821 19.7978Z" fill="#B1BD52"/>
                                <path d="M20.2944 9.55848L16.2272 5.46057L15.55 6.14281L19.6173 10.2407L20.2944 9.55848Z" fill="#B1BD52"/>
                                <path d="M17.5851 9.56445L14.8699 6.8288L14.1927 7.51104L16.9079 10.2467L17.5851 9.56445Z" fill="#B1BD52"/>
                                <path d="M16.225 13.654L15.5479 12.9717L14.8707 13.654L15.5479 14.3362L16.225 13.654Z" fill="#B1BD52"/>
                                <path d="M16.2295 10.9256L13.5165 8.1922L12.8393 8.87444L15.5523 11.6079L16.2295 10.9256Z" fill="#B1BD52"/>
                                <path d="M13.5184 10.9259L12.1597 9.55695L11.4825 10.2392L12.8412 11.6081L13.5184 10.9259Z" fill="#B1BD52"/>
                                <path d="M17.5839 9.56291L14.1982 12.9741L14.8754 13.6564L18.2611 10.2452L17.5839 9.56291Z" fill="#B1BD52"/>
                                <path d="M17.7096 12.1625L15.5498 14.3386L16.2269 15.0208L18.3867 12.8448L17.7096 12.1625Z" fill="#B1BD52"/>
                                <path d="M14.8758 9.56345L12.8444 11.6102L13.5215 12.2924L15.5529 10.2457L14.8758 9.56345Z" fill="#B1BD52"/>
                                <path d="M16.2256 5.4658L14.3203 7.38544L14.9975 8.06768L16.9027 6.14804L16.2256 5.4658Z" fill="#B1BD52"/>
                                <path d="M20.2918 9.56125L18.3865 11.4809L19.0636 12.1631L20.9689 10.2435L20.2918 9.56125Z" fill="#B1BD52"/>
                                <path d="M11.4833 7.50884L10.8062 6.8266L9.45409 8.18885L10.1312 8.87109L11.4833 7.50884Z" fill="white"/>
                                <path d="M12.1443 12.2759L11.4672 11.5936L10.1151 12.9559L10.7922 13.6381L12.1443 12.2759Z" fill="white"/>
                                <path d="M9.45221 10.9194L8.09351 9.55048L7.41637 10.2327L8.77507 11.6017L9.45221 10.9194Z" fill="white"/>
                                <path d="M14.1972 10.2437L12.8385 8.87476L12.1614 9.557L13.5201 10.9259L14.1972 10.2437Z" fill="white"/>
                            </svg>
                            </span>
                            <!-- <a class="tl">{{ mainNews[0].cat }}</a> -->
                            <router-link :to="'/newscat/' + mainNews[0].cat_req + '?&lang=' +$i18n.locale" class="tl" >{{ mainNews[0].cat }}</router-link>
                        </div>
                        <div class="date">
                            <span><img :src="$vuetify.theme.dark ? '/assets/img/date_big_white.svg' : '/assets/img/date_big_dark.svg'" /></span>
                            <p class="tl d-none d-sm-flex">{{ date }}</p>
                            <p class="tl d-flex d-sm-none" v-if="mainNews[0].video">{{ mainNews[0].updated|FormatDate }}</p>
                            <p class="tl d-flex d-sm-none" v-else>{{ mainNews[0].time|FormatDate }}</p>
                            <!-- <p class="tl d-flex d-sm-none" >{{ mainNews[0].updated|FormatDate }}</p> -->
                        </div>

                        <div class="date mx-1">
                            <span>
                                <svg width="18" height="18" viewBox="0 0 16 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g clip-path="url(#clip0_2866_348)">
                                <path d="M0.666992 7.99984C0.666992 7.99984 3.33366 2.6665 8.00033 2.6665C12.667 2.6665 15.3337 7.99984 15.3337 7.99984C15.3337 7.99984 12.667 13.3332 8.00033 13.3332C3.33366 13.3332 0.666992 7.99984 0.666992 7.99984Z" stroke="#B1BD52" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M8 10C9.10457 10 10 9.10457 10 8C10 6.89543 9.10457 6 8 6C6.89543 6 6 6.89543 6 8C6 9.10457 6.89543 10 8 10Z" stroke="#B1BD52" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                </g>
                                <defs>
                                <clipPath id="clip0_2866_348">
                                <rect width="16" height="16" fill="white"/>
                                </clipPath>
                                </defs>
                                </svg>
                            </span> 
                            <span class="tl">{{mainNews[0].hits}}</span>
                        </div>

                        <div class="pic_num d-none">
                            <span>
                            <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M15.575 1.14067C15.2969 0.853168 14.9 0.684418 14.5 0.687543C11.0531 0.687543 7.60313 0.687543 4.15312 0.687543C3.57187 0.687543 3.0125 1.05942 2.7875 1.59379C2.7 1.78754 2.6625 2.00317 2.66563 2.21567C2.66563 4.6438 2.66563 7.07192 2.66563 9.50005C2.66563 9.67505 2.65938 9.85005 2.675 10.025C2.6875 10.1469 2.66563 10.2813 2.73125 10.3938C2.77813 10.4782 2.84063 10.5532 2.8875 10.6375C3.12188 11.0251 3.54375 11.2969 3.99688 11.3438C4.13125 11.3594 4.26875 11.3563 4.40625 11.3563C7.75938 11.3563 11.1125 11.3563 14.4656 11.3563C14.8875 11.3626 15.3094 11.1907 15.5938 10.8782C15.8375 10.6219 15.975 10.2782 15.9969 9.92505V2.13442C15.9844 1.76567 15.8344 1.40629 15.575 1.14067ZM15 9.87505C14.9969 10.1344 14.7563 10.3594 14.5 10.3532C11.0625 10.3532 7.625 10.3532 4.1875 10.3532C4.07813 10.3594 3.97813 10.3219 3.8875 10.2688C3.8875 10.2844 3.88438 10.2938 3.88438 10.2938C3.88438 10.2938 3.88125 10.2844 3.88125 10.2657C3.86875 10.2563 3.85625 10.2501 3.84063 10.2407C3.85 10.2282 3.86563 10.2 3.875 10.1844C3.86875 9.93442 3.86563 9.30005 3.86875 8.77505C3.8 8.8438 3.73125 8.91255 3.6625 8.9813C3.66562 6.71567 3.6625 4.45005 3.66563 2.18754C3.6625 1.99067 3.79062 1.80004 3.975 1.72504C4.08125 1.67817 4.2 1.68754 4.3125 1.68754C7.70937 1.68754 11.1031 1.68754 14.5 1.68754C14.7656 1.68129 15.0031 1.92192 14.9969 2.18754C14.9969 3.95005 14.9969 5.71255 14.9969 7.47817C14.9937 7.47505 14.9906 7.4688 14.9875 7.46567C14.9906 7.90317 14.9906 8.58442 14.9875 9.00005C14.9906 9.00317 14.9937 9.0063 14.9969 9.01255C15 9.29692 15 9.58755 15 9.87505Z" fill="#9A9A9A"/>
                                <path d="M14.9688 7.44063C14.1938 6.53751 13.4188 5.63438 12.6469 4.73126C12.4906 4.54063 12.3063 4.35938 12.0656 4.27813C11.6125 4.12501 11.0719 4.27188 10.7688 4.64688C9.94688 5.63438 9.12188 6.62188 8.3 7.61251C8.04063 7.35938 7.7875 7.10001 7.53125 6.84688C7.3875 6.70001 7.2125 6.58126 7.01562 6.52188C6.61562 6.39376 6.15625 6.50001 5.85625 6.79376C5.20312 7.44688 4.55 8.10001 3.89375 8.75313C3.89687 9.27188 3.89375 9.90938 3.8875 10.175C4.7375 9.32501 5.5875 8.47501 6.4375 7.62501C6.49687 7.56876 6.55 7.49688 6.625 7.45938C6.6875 7.43438 6.74687 7.47813 6.79062 7.52188C7.19375 7.92188 7.59375 8.32813 7.99687 8.72501C8.19063 8.91563 8.54063 8.89063 8.7125 8.68126C9.65625 7.55313 10.5969 6.41876 11.5406 5.29063C11.6031 5.20001 11.7438 5.20626 11.8094 5.29063C12.8656 6.51876 13.9156 7.75001 14.9719 8.97813C14.9656 8.55001 14.9656 7.86876 14.9688 7.44063Z" fill="#B1BD52"/>
                                <path d="M5.76875 2.70946C6.11875 2.64696 6.49688 2.73134 6.78438 2.94384C7.10938 3.17821 7.31875 3.56571 7.33125 3.96884C7.34688 4.30321 7.22813 4.64071 7.00938 4.89071C6.78125 5.15946 6.44375 5.33134 6.09063 5.35321C5.67188 5.38446 5.24063 5.20009 4.975 4.87509C4.6875 4.53759 4.59375 4.05009 4.725 3.62821C4.86875 3.15634 5.28438 2.79071 5.76875 2.70946ZM5.91563 3.70009C5.67813 3.75009 5.58438 4.07821 5.75938 4.24696C5.91563 4.43134 6.25313 4.35946 6.31875 4.12509C6.40625 3.88759 6.15625 3.62196 5.91563 3.70009Z" fill="#B1BD52"/>
                                <path d="M1.1875 6.3438C1.26875 6.03443 1.67188 5.8813 1.93438 6.06568C2.11563 6.17818 2.20625 6.41255 2.15 6.6188C1.77188 8.0688 1.39375 9.5188 1.01562 10.9719C0.946875 11.2282 1.12188 11.5188 1.38125 11.5813C4.75313 12.4844 8.12187 13.3844 11.4937 14.2907C11.6187 14.3219 11.7469 14.3719 11.8781 14.3501C12.075 14.3219 12.2469 14.1657 12.2906 13.9688C12.4094 13.4938 12.525 13.0188 12.6469 12.5438C12.7156 12.2782 13.0312 12.1188 13.2875 12.2126C13.5219 12.2876 13.675 12.5563 13.6125 12.7969C13.525 13.1563 13.4344 13.5157 13.3469 13.8719C13.2844 14.1188 13.2406 14.3751 13.1062 14.5969C12.8594 15.0344 12.3813 15.3282 11.8781 15.3469C11.5781 15.3688 11.2906 15.2594 11.0063 15.1876C7.70938 14.3032 4.4125 13.4219 1.1125 12.5376C0.496875 12.3813 0.0375 11.8032 0 11.1751V10.9876C0.025 10.7594 0.09375 10.5376 0.15 10.3157C0.496875 8.99068 0.840625 7.6688 1.1875 6.3438Z" fill="#9A9A9A"/>
                            </svg>
                            </span>
                            <p class="tl">{{$t("attached_pic_num") + ' ' + mainNews[0].pic_num }}</p>
                        </div>
                    </div>
                    <div class="media">
                        <div class="img" v-if="!mainNews[0].video">
                            <template v-if="mainNews[0].id > 652" >
                                <a data-fancybox="gallery" :data-caption="mainNews[0].comment ? mainNews[0].comment : mainNews[0].title1" :href="'https://alkafeel.net/news/images/main/' + mainNews[0].image" class="">
                                    <img :src="'https://alkafeel.net/news/mcroped96/600/'+mainNews[0].image" />
                                </a>
                            </template>
                            <template v-else>
                                <img :src="'https://alkafeel.net/alkafeelnews/up2/'+mainNews[0].image" />
                            </template>
                            
                            
                        </div>
                        <div class="vid" v-else>
                            <div class="vidCon">
                    
                                <video ref="videoPlayer" class="video-js vjs-theme-fantasy" :poster="'https://alkafeel.net/news/mcroped96/800/'+mainNews[0].image" preload="auto"  width="800" height="500">
                              
                            </video>

                            </div>
                        </div>
                    </div>
                    <div class="social">
                        <div :class="'share_icon ' + $store.state.direction">
                            <img :src="$vuetify.theme.dark ? '/assets/img/news/share_white.svg' : '/assets/img/news/share_dark.svg'" />
                        </div>
                        <a :href="'https://telegram.me/share/?url=https://alkafeel.net/news/?id='+mainNews[0].id" target="_blank" class="telegram">
                            <img :src="$vuetify.theme.dark ? '/assets/img/news/Telegram_white.svg' : '/assets/img/news/Telegram_dark.svg'" />
                        </a>
                        <a :href="'https://twitter.com/intent/tweet?text=https://alkafeel.net/news/?id='+mainNews[0].id" target="_blank" class="twitter">
                            <img :src="$vuetify.theme.dark ? '/assets/img/news/twitter_white.svg' : '/assets/img/news/twitter_dark.svg'" />
                        </a>
                        <a :href="'https://www.facebook.com/sharer/sharer.php?u=https://alkafeel.net/news/?id='+mainNews[0].id" target="_blank" class="facebook">
                            <img :src="$vuetify.theme.dark ? '/assets/img/news/Facebook_white.svg' : '/assets/img/news/Facebook_dark.svg'" />
                        </a>
                        <p :class="'share_text tl ' + $store.state.direction">{{$t("share_subject")}}</p>
                    </div>
                    <p class="tl" v-if="mainNews[0].writer"><span>{{ mainNews[0].writer }}</span></p>
                    <p :class="$i18n.locale=='ar' ? 'txx' : 'tx'" v-html="bbcode(mainNews[0].text)" v-if="relatedInnerRes.length == 0"></p>
                    <div class="relatedInner" v-else>
                        <p :class="$i18n.locale=='ar' ? 'txx' : 'tx'" v-html="reCreateText(mainNews[0].text,1)"></p>
                        <div class="items">
                            <p class="tl relatedtitle">{{ $t("related_news") }}</p>
                            <router-link :to="'/new/' + i.id + '?&lang=' +$i18n.locale" v-for="i in relatedInnerRes" :key="i" class="item Oitem" >
                                <div class="img">
                                    <v-img :lazy-src="'https://alkafeel.net/news/mcroped96/100/'+i.image"
                                        :src="'https://alkafeel.net/news/mcroped96/260/'+i.image" style="height: 100%; width: 100%; border-radius: 4px;">
                                    </v-img>
                                    <div class="icon" v-if="i.video">
                                        <svg width="54" height="55" viewBox="0 0 54 55" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="27.1089" cy="27.7103" r="26.8329" fill="#B1BD52" fill-opacity="0.8"/>
                                        <path d="M39.0253 26.8443C39.692 27.2292 39.692 28.1915 39.0253 28.5764L21.9007 38.4633C21.234 38.8482 20.4007 38.3671 20.4007 37.5973V17.8235C20.4007 17.0537 21.234 16.5725 21.9007 16.9574L39.0253 26.8443Z" fill="white"/>
                                        </svg>
                                    </div>
                                </div>
                                <div :class="'paregs ' + $store.state.direction">
                                    <p class="tl d-none d-sm-flex" v-html="i.title"></p>
                                    <p class="tl d-flex d-sm-none" v-html="subStr(i.title)"></p>
                                </div>
                            </router-link>
                        </div>
                        <p :class="$i18n.locale=='ar' ? 'txx' : 'tx'" v-html="reCreateText(mainNews[0].text,2)"></p>
                    </div>
                    <div class="mainimgs" id="mainimgs" v-if="mainNews[0].is_photonews != 1">
                        
                            <v-layout row >
                                <v-flex sm6 xs12 md6 lg6 v-for="i in mainimgs" :key="i" v-if="i.title" class="big">
                                    <a data-fancybox="gallery" :data-caption="i.title" :href="i.full_url" class="">
                                        <!-- <img :src="'https://alkafeel.net/news/images/attach/resized/' + i.news_id + '-' + i.img + '.' + i.ext" />
                                        <p class="tl ta" style="font-size: 10px;">{{i.title|subStrmostNewsitlexx}}</p> -->
                                        <v-img :src="i.thumb_url">
                                            <div class="gradiant_img fill-height">
                                                <span class="img_title">{{i.title|subStrmostNewsitle}}</span>
                                            </div>
                                        </v-img>
                                    </a>
                                </v-flex>
                                <v-flex sm12 xs12 md12 lg12 v-if="mainimgs.length > 2"></v-flex>
                                <v-flex sm3 xs6 md4 lg3 v-for="i in mainimgs" :key="i" v-if="!i.title" class="small">
                                    <a data-fancybox="gallery" :data-caption="i.title" :href="i.full_url" class="">
                                        <img :src="i.thumb_url" />
                                    </a>
                                </v-flex>
                            </v-layout>
                    
                        
                        <!-- <v-flex sm3 xs6 md4 lg3 v-if="getMoreimg">
                            <a class="defImg" @click.prevent="grtmainNewsimgs()">
                                <div class="img">
                                    <img :src="'https://alkafeel.net/news/images/attach/resized/' + mainimgs[0].news_id + '-' + mainimgs[0].img + '.' + mainimgs[0].ext" />
                                    <span></span>
                                </div>
                                <p>+ {{attached_imgs}}</p>
                            </a>
                        </v-flex> -->
                    </div>
                    <div class="mainimgs is_photonews" id="mainimgs" v-else>
                        <v-layout row >
                            <v-flex sm6 xs6 md6 lg6 v-for="i in mainimgs" :key="i">
                                <a data-fancybox="gallery" :data-caption="i.title" :href="i.full_url" class="">
                                    <img :src="i.thumb_url" />
                                </a>
                            </v-flex>
                        <!-- <v-flex sm6 xs6 md6 lg6 v-if="getMoreimg">
                            <a class="defImg" @click.prevent="grtmainNewsimgs()">
                                <img :src="'https://alkafeel.net/news/images/attach/resized/' + mainimgs[0].news_id + '-' + mainimgs[0].img + '.' + mainimgs[0].ext" />
                                <span></span>
                                <p>+ {{attached_imgs}}</p>
                            </a>
                        </v-flex> -->
                        </v-layout>
                    </div>
                    <div class="tags" v-if="tagRes.length != 0">
                        <div class="title">
                            
                            <p class="tl">{{ $t("tags") }}</p>
                        </div>
                        <div class="items">
                            <router-link :to="'/newstag/'+ i.id + '?&lang=' +$i18n.locale" v-for="i in tagRes" :key="i" class="item" >
                                <span>#</span><p>{{i.title}}</p>
                            </router-link>
                        </div>
                    </div>
                    <div class="comments">
                        <div class="readers" v-if="showcomments">
                            <div class="title">
                                <span><img :src="$vuetify.theme.dark ? '/assets/img/z-mohammed_dark.svg' : '/assets/img/z-mohammed.svg'" /></span>
                                <p class="tl">{{ $t("news_add-comment") }}</p>
                            </div>
                            <div class="items">
                                <div class="item_con" style="margin:35px 0" v-for="i in commentsres" :key="i">
                                    <div class="item">
                                        <div class="imgt">
                                            <!-- <img src="https://alkafeel.net/news/mcroped96/625/c79e2ff582bd0b443650ae8e4fd2bc87.JPG" :alt="i.name" /> -->
                                            <div class="img">
                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 144.1 192" fill="#605c5c" xmlns:v="https://vecta.io/nano"><path d="M56.8 1.9C64.2-.5 72.2 0 79.9.3c17 1.4 31.6 16.6 32.1 33.7.1 11 .4 22-.1 33-.7 11.4-6.8 22.1-15.8 28.9.2 3 0 6.1.1 9.1.4 1.1 1.9 1.3 2.8 1.9l33.7 16.8c6.8 3.3 11.7 10.6 11.5 18.3l-.1 46.9c-.7 4.5-8.1 3.6-7.9-.9l-.1-47.1c-.2-4.2-2.9-8-6.7-9.8l-34-17c-1.6 6.2-5.8 11.5-11.3 14.8.3 7.7.1 15.4 0 23.1.2 6.4-5.5 12.2-11.9 12-6.4.3-12.2-5.5-12-11.9 0-7.7-.2-15.4.1-23.1-5.5-3.3-9.5-8.7-11.5-14.9-11.3 5.9-22.9 11.4-34.2 17.2-3.9 2-6.5 6.3-6.2 10.7l-.1 46.2c.1 4.5-7.3 5.2-7.9.8-.3-12.3-.2-24.6-.1-36.9 0-5-.6-10 .5-15 1.4-5.9 5.8-10.9 11.3-13.5l36.1-17.9c.2-3.3 0-6.5 0-9.7-9.2-7.1-15.4-18.2-15.8-29.9-.1-6-.3-12-.1-18 .1-7.7-.8-15.5 1.7-22.9C37.2 14.3 46 5.4 56.8 1.9zm2.1 7.7c-9.7 3.3-17.2 12.3-18.5 22.5h63.4c-1.7-13-13.5-23.9-26.7-23.9-6 .1-12.4-.8-18.2 1.4zM40.1 40.1l.1 27c.6 8.1 4.7 15.8 10.8 21.1 9.3 8.4 23.7 10.2 34.8 4.8 10.2-4.8 17.6-15.4 18-26.8.4-8.7.2-17.3 0-26l-63.7-.1zm16 60.6c.2 5.8-.8 12.3 3.2 17 5.7 8.1 18.9 8.4 25 .6 4.4-4.8 3.7-11.7 3.8-17.7-10 4.5-21.9 4.6-32 .1zM68 131.8l.2 21.2c.6 3.9 7.2 4 7.7 0 .3-7.1.3-14.1.3-21.2-2.8.2-5.5.2-8.2 0zm-37.6 24.6c2.5-1.2 5.6.8 5.8 3.5-.2 7.1 0 14.1-.2 21.1-.2 3.1.9 6.6-.7 9.5-2 2.9-7.5 1.4-7.2-2.4l-.2-25.1c.1-2.3-.1-5.5 2.5-6.6zm80 0c2.1-1 5.1.3 5.5 2.7.7 6.6-.1 13.3.1 19.9-.1 3.6.5 7.2-.3 10.7-1.2 3-6 3-7.3 0-1-3.2-.2-6.5-.3-9.7-.2-6 .1-12 0-18 0-2.1 0-4.8 2.3-5.6z"/></svg>
                                            </div>
                                            <p class="tl">{{i.name}}</p>
                                        </div>
                                        <p class="tl">{{i.text}}</p>
                                    </div>
                                    <div v-if="i.reply != null" :class="'Subitem ' + $store.state.direction">
                                        <div class="imgt">
                                            <img src="https://alkafeel.net/images/ico1.png" :alt="i.name" />
                                            <p class="tl">{{$t('index_imam_abbas')}}</p>
                                        </div>
                                        <p class="tl">{{i.reply}}</p>
                                    </div>
                                </div>
                                <v-btn v-if="getMorecomments" width="150" height="40" class="ma-2 tl" style="align-self:center;" :loading="loading" color="rgba(177, 189, 82, 1)" @click="grtcomments()">
                                    <p class="alistSub tl">{{ $t('view_more') }}</p>
                                </v-btn>
                                <p class="no_comment" v-if="!getMorecomments">{{ $t('no_more_comment') }}</p>
                            </div>
                        </div>
                        <div class="add">
                            <div class="title">
                                <span><img :src="$vuetify.theme.dark ? '/assets/img/z-mohammed_dark.svg' : '/assets/img/z-mohammed.svg'" /></span>
                                <p class="tl">{{ $t("add_comment") }}</p>
                            </div>
                            <v-form class="commentsForm" ref="form" v-model="valid">
                                <div class="F_Cont">
                                    <v-text-field @keyup.enter.native="comment_send()" v-model="comments.name" background-color="rgba(255, 255, 255, 0.4)" :rules="[rules.required]" :placeholder="$t('name')" outlined></v-text-field>
                                    <div style="margin:0 10px;" class="d-none d-sm-flex"></div>
                                    <v-text-field @keyup.enter.native="comment_send()" v-model="comments.email" background-color="rgba(255, 255, 255, 0.4)" :rules="[rules.email]" :placeholder="$t('msg_email_optional')" outlined></v-text-field>
                                </div>
                                <v-textarea style="width:100%" v-model="comments.comment" background-color="rgba(255, 255, 255, 0.4)" :rules="[rules.required]" :placeholder="$t('Comment_text')" outlined></v-textarea>
                                <v-btn width="150" height="40" class="ma-2 tl" :loading="loading2" color="rgba(177, 189, 82, 1)" @click="comment_send()">
                                    <p class="alistSub tl">{{ $t('action_send') }}</p>
                                </v-btn>
                            </v-form>
                        </div>
                    </div>
                </div>
            </v-flex>
            <v-flex sm12 xs12 md3 lg3 class="d-none d-sm-none d-md-flex" style="padding:0 20px;" v-if="sideNews_skeleton">
                <div class="sidebar">
                    <br /><br /><br />
                    <v-skeleton-loader  width="300" min-width="300" type="card"></v-skeleton-loader>
                    <v-skeleton-loader  width="300" min-width="300" type="card"></v-skeleton-loader>
                    <v-skeleton-loader  width="300" min-width="300" type="card"></v-skeleton-loader>
                    <v-skeleton-loader  width="300" min-width="300" type="card"></v-skeleton-loader>
                </div>
            </v-flex>
            <v-flex sm12 xs12 md3 lg3 class="d-none d-sm-none d-md-flex" style="padding:0 20px;" v-else>
                <div class="sidebar">
                    <div class="related" v-if="relatedNews.length != 0">
                        <div class="title">
                            <span><img :src="$vuetify.theme.dark ? '/assets/img/z-mohammed_dark.svg' : '/assets/img/z-mohammed.svg'" /></span>
                            <p class="tl">{{ $t("related_news") }}</p>
                        </div>
                        <div class="items">
                            <router-link :to="'/new/'+ i.id + '?&lang=' +$i18n.locale" v-for="i in relatedNews" :key="i" class="item" >
                                <div class="img">
                                    <v-img :lazy-src="'https://alkafeel.net/news/mcroped96/100/'+i.image"
                                        :src="'https://alkafeel.net/news/mcroped96/275/'+i.image" style="border-radius: 4px;">
                                    </v-img>
                                    <div class="icon" v-if="i.video">
                                        <svg width="54" height="55" viewBox="0 0 54 55" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="27.1089" cy="27.7103" r="26.8329" fill="#B1BD52" fill-opacity="0.8"/>
                                        <path d="M39.0253 26.8443C39.692 27.2292 39.692 28.1915 39.0253 28.5764L21.9007 38.4633C21.234 38.8482 20.4007 38.3671 20.4007 37.5973V17.8235C20.4007 17.0537 21.234 16.5725 21.9007 16.9574L39.0253 26.8443Z" fill="white"/>
                                        </svg>
                                    </div>
                                    <div class="date">
                                        <!-- <span><img :src="$vuetify.theme.dark ? '/assets/img/date_small_white.svg' : '/assets/img/date_small_dark.svg'" /></span> -->
                                        <p class="tl">{{ i.time|FormatDate }}</p>
                                    </div>
                                </div>
                                <p class="tl" v-html="$options.filters.subStrmostNewsitle(i.title)"></p>
                                
                            </router-link>
                        </div>
                    </div>
                    <div class="social">
                        <div class="title">
                            <span><img :src="$vuetify.theme.dark ? '/assets/img/z-mohammed_dark.svg' : '/assets/img/z-mohammed.svg'" /></span>
                            <p class="tl">{{ $t("social_links") }}</p>
                        </div>
                        <div class="items">
                            <a :href="i.link" v-for="i in social" :key="i" :class="'' + i.app" target="_blank">
                                <span v-if="i.app == 'facebook'" :class="$store.state.direction">
                                    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M0 16C0 7.16344 7.16344 0 16 0C24.8366 0 32 7.16344 32 16C32 24.8366 24.8366 32 16 32C7.16344 32 0 24.8366 0 16ZM17.6677 25.4077V16.7028H20.0706L20.3891 13.7031H17.6677L17.6718 12.2017C17.6718 11.4193 17.7461 11.0001 18.8698 11.0001H20.372V8H17.9688C15.0821 8 14.066 9.4552 14.066 11.9024V13.7034H12.2667V16.7031H14.066V25.4077H17.6677Z" fill="#F2F2F2"/>
                                    </svg>
                                </span>
                                <span v-if="i.app == 'twitter'" :class="$store.state.direction">
                                    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M0 16C0 7.16344 7.16344 0 16 0C24.8366 0 32 7.16344 32 16C32 24.8366 24.8366 32 16 32C7.16344 32 0 24.8366 0 16ZM15.5208 13.005L15.5544 13.5586L14.9948 13.4908C12.9579 13.231 11.1784 12.3497 9.66756 10.8695L8.92891 10.1351L8.73865 10.6775C8.33575 11.8864 8.59316 13.1632 9.43253 14.0219C9.8802 14.4964 9.77948 14.5642 9.00725 14.2818C8.73865 14.1914 8.50363 14.1236 8.48124 14.1575C8.4029 14.2366 8.6715 15.2647 8.88414 15.6715C9.17513 16.2364 9.76828 16.7901 10.4174 17.1177L10.9658 17.3776L10.3167 17.3889C9.68994 17.3889 9.66756 17.4002 9.73471 17.6375C9.95854 18.3719 10.8427 19.1515 11.8276 19.4905L12.5214 19.7277L11.9171 20.0893C11.0218 20.609 9.96973 20.9028 8.91772 20.9254C8.41409 20.9367 8 20.9819 8 21.0158C8 21.1288 9.36538 21.7615 10.16 22.0101C12.5438 22.7445 15.3753 22.4281 17.5017 21.174C19.0126 20.2814 20.5235 18.5075 21.2286 16.7901C21.6091 15.8749 21.9896 14.2027 21.9896 13.4005C21.9896 12.8807 22.0232 12.8129 22.6499 12.1915C23.0192 11.8299 23.3662 11.4345 23.4333 11.3215C23.5452 11.1068 23.534 11.1068 22.9633 11.2989C22.012 11.6379 21.8777 11.5927 22.3477 11.0842C22.6947 10.7227 23.1088 10.0673 23.1088 9.87526C23.1088 9.84137 22.9409 9.89786 22.7506 9.99955C22.5492 10.1125 22.1015 10.282 21.7658 10.3837L21.1614 10.5758L20.613 10.2029C20.3108 9.99955 19.8856 9.77357 19.6617 9.70578C19.0909 9.5476 18.218 9.5702 17.7032 9.75098C16.3042 10.2594 15.4201 11.5701 15.5208 13.005Z" fill="#F2F2F2"/>
                                    </svg>
                                </span>
                                <span v-if="i.app == 'youtube'" :class="$store.state.direction">
                                    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M0 16C0 7.16344 7.16344 0 16 0C24.8366 0 32 7.16344 32 16C32 24.8366 24.8366 32 16 32C7.16344 32 0 24.8366 0 16ZM24.1767 12.0488C23.9804 11.2948 23.4021 10.7011 22.6677 10.4996C21.3369 10.1333 16 10.1333 16 10.1333C16 10.1333 10.6631 10.1333 9.33217 10.4996C8.59782 10.7011 8.01949 11.2948 7.82322 12.0488C7.46667 13.4154 7.46667 16.2667 7.46667 16.2667C7.46667 16.2667 7.46667 19.1179 7.82322 20.4845C8.01949 21.2385 8.59782 21.8323 9.33217 22.0339C10.6631 22.4 16 22.4 16 22.4C16 22.4 21.3369 22.4 22.6677 22.0339C23.4021 21.8323 23.9804 21.2385 24.1767 20.4845C24.5333 19.1179 24.5333 16.2667 24.5333 16.2667C24.5333 16.2667 24.5333 13.4154 24.1767 12.0488Z" fill="#F2F2F2"/>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M14.4 19.2V13.8667L18.6667 16.5334L14.4 19.2Z" fill="#F2F2F2"/>
                                    </svg>
                                </span>
                                <span v-if="i.app == 'instagram'" :class="$store.state.direction">
                                    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M0 16C0 7.16344 7.16344 0 16 0C24.8366 0 32 7.16344 32 16C32 24.8366 24.8366 32 16 32C7.16344 32 0 24.8366 0 16ZM16.0008 7.46667C13.6833 7.46667 13.3924 7.4768 12.4822 7.51822C11.5737 7.55982 10.9536 7.70365 10.4111 7.91467C9.8498 8.13263 9.37371 8.42419 8.89939 8.89868C8.42472 9.373 8.13316 9.84909 7.91449 10.4102C7.70294 10.9529 7.55893 11.5732 7.51805 12.4813C7.47733 13.3915 7.46667 13.6826 7.46667 16.0001C7.46667 18.3176 7.47698 18.6076 7.51822 19.5178C7.56 20.4263 7.70382 21.0464 7.91467 21.5889C8.13281 22.1502 8.42437 22.6263 8.89886 23.1006C9.373 23.5753 9.84909 23.8675 10.41 24.0855C10.9529 24.2965 11.5732 24.4404 12.4815 24.482C13.3917 24.5234 13.6824 24.5335 15.9997 24.5335C18.3174 24.5335 18.6074 24.5234 19.5176 24.482C20.4261 24.4404 21.0469 24.2965 21.5898 24.0855C22.1509 23.8675 22.6263 23.5753 23.1004 23.1006C23.5751 22.6263 23.8667 22.1502 24.0853 21.5891C24.2951 21.0464 24.4391 20.4261 24.4818 19.518C24.5227 18.6078 24.5333 18.3176 24.5333 16.0001C24.5333 13.6826 24.5227 13.3917 24.4818 12.4815C24.4391 11.573 24.2951 10.9529 24.0853 10.4103C23.8667 9.84909 23.5751 9.373 23.1004 8.89868C22.6258 8.42401 22.1511 8.13245 21.5893 7.91467C21.0453 7.70365 20.4248 7.55982 19.5164 7.51822C18.6062 7.4768 18.3164 7.46667 15.9981 7.46667H16.0008Z" fill="#F2F2F2"/>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M15.2353 9.00446C15.4625 9.00411 15.716 9.00446 16.0008 9.00446C18.2792 9.00446 18.5493 9.01264 19.449 9.05353C20.281 9.09157 20.7326 9.2306 21.0334 9.3474C21.4316 9.50207 21.7155 9.68696 22.014 9.98563C22.3127 10.2843 22.4976 10.5687 22.6526 10.967C22.7694 11.2674 22.9086 11.719 22.9465 12.551C22.9874 13.4506 22.9963 13.7208 22.9963 15.9981C22.9963 18.2755 22.9874 18.5457 22.9465 19.4453C22.9084 20.2773 22.7694 20.7288 22.6526 21.0293C22.4979 21.4275 22.3127 21.7111 22.014 22.0096C21.7153 22.3082 21.4318 22.4931 21.0334 22.6478C20.7329 22.7651 20.281 22.9038 19.449 22.9418C18.5494 22.9827 18.2792 22.9916 16.0008 22.9916C13.7222 22.9916 13.4522 22.9827 12.5526 22.9418C11.7206 22.9034 11.269 22.7644 10.968 22.6476C10.5698 22.493 10.2854 22.3081 9.98669 22.0094C9.68802 21.7107 9.50313 21.427 9.34811 21.0286C9.23131 20.7281 9.09211 20.2766 9.05424 19.4446C9.01335 18.545 9.00517 18.2748 9.00517 15.996C9.00517 13.7172 9.01335 13.4484 9.05424 12.5489C9.09228 11.7168 9.23131 11.2653 9.34811 10.9645C9.50278 10.5663 9.68802 10.2818 9.98669 9.98314C10.2854 9.68447 10.5698 9.49958 10.968 9.34455C11.2688 9.22722 11.7206 9.08855 12.5526 9.05033C13.3398 9.01477 13.6449 9.00411 15.2353 9.00233V9.00446ZM20.5559 10.4214C19.9905 10.4214 19.5319 10.8795 19.5319 11.445C19.5319 12.0104 19.9905 12.469 20.5559 12.469C21.1212 12.469 21.5799 12.0104 21.5799 11.445C21.5799 10.8797 21.1212 10.4214 20.5559 10.4214ZM16.0008 11.6178C13.5807 11.6178 11.6185 13.58 11.6185 16.0001C11.6185 18.4202 13.5807 20.3815 16.0008 20.3815C18.4209 20.3815 20.3824 18.4202 20.3824 16.0001C20.3824 13.58 18.4209 11.6178 16.0008 11.6178Z" fill="#F2F2F2"/>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M16.0008 13.1556C17.5717 13.1556 18.8453 14.4291 18.8453 16.0001C18.8453 17.571 17.5717 18.8446 16.0008 18.8446C14.4298 18.8446 13.1563 17.571 13.1563 16.0001C13.1563 14.4291 14.4298 13.1556 16.0008 13.1556Z" fill="#F2F2F2"/>
                                    </svg>
                                </span>
                                <span v-if="i.app == 'telegram'" :class="$store.state.direction">
                                    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M32 16C32 24.8365 24.8365 32 16 32C7.16347 32 0 24.8365 0 16C0 7.16347 7.16347 0 16 0C24.8365 0 32 7.16347 32 16ZM10.7143 14.5343C9.11153 15.2341 7.46473 15.9532 5.95883 16.7827C5.17251 17.3584 6.21759 17.7657 7.19807 18.1477C7.35393 18.2084 7.50813 18.2685 7.65313 18.3285C7.7738 18.3656 7.89647 18.4047 8.0208 18.4443C9.11127 18.7917 10.3271 19.1791 11.3858 18.5963C13.1249 17.5973 14.766 16.4424 16.4059 15.2883C16.9432 14.9102 17.4803 14.5322 18.0207 14.1598C18.0461 14.1436 18.0746 14.1251 18.1059 14.1049C18.5662 13.8064 19.6016 13.1353 19.2186 14.0601C18.313 15.0505 17.3429 15.9271 16.3677 16.8087C15.7103 17.4027 15.0506 17.999 14.4066 18.6337C13.8457 19.0894 13.2633 20.0059 13.8913 20.6441C15.3379 21.6567 16.8071 22.6449 18.2755 23.6325C18.7533 23.9538 19.2311 24.2751 19.7079 24.5973C20.516 25.2425 21.779 24.7205 21.9567 23.7123C22.0357 23.2485 22.115 22.7848 22.1943 22.321C22.6327 19.7579 23.0713 17.1938 23.4587 14.6223C23.5113 14.2189 23.571 13.8156 23.6307 13.4121C23.7755 12.434 23.9204 11.4547 23.9657 10.4714C23.849 9.49007 22.6591 9.70587 21.997 9.92653C18.5935 11.2216 15.2241 12.6127 11.8679 14.0283C11.4877 14.1967 11.1023 14.3649 10.7143 14.5343Z" fill="#F2F2F2"/>
                                    </svg>
                                </span>
                                <p class="tl" style="line-height: 1.9;" :class="$store.state.direction">{{i.num + ' ' + $t("subscriber") }}</p>
                            </a>
                        </div>
                    </div>
                </div>
            </v-flex>
        </v-layout>


        <v-layout row>
            <div class="related2 d-md-none"   v-if="relatedNews.length != 0">
                <div class="title">
                    <span><img :src="$vuetify.theme.dark ? '/assets/img/z-mohammed_dark.svg' : '/assets/img/z-mohammed.svg'" /></span>
                    <p class="tl">{{ $t("related_news") }}</p>
                </div>
                <div class="slider">
                    <swiper class="swiper dirr" ref="mySwiper" :options="swiperOptions1">
                        <swiper-slide v-for="i in relatedNews" :key="i">
                        <router-link :to="'/new/'+ i.id + '?&lang=' +$i18n.locale" class="item" >
                            <div class="img">
                                <img :src="'https://alkafeel.net/news/mcroped96/275/' + i.image" :alt="i.title1" />
                                <div class="icon" v-if="i.video">
                                    <svg width="54" height="55" viewBox="0 0 54 55" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="27.1089" cy="27.7103" r="26.8329" fill="#B1BD52" fill-opacity="0.8"/>
                                    <path d="M39.0253 26.8443C39.692 27.2292 39.692 28.1915 39.0253 28.5764L21.9007 38.4633C21.234 38.8482 20.4007 38.3671 20.4007 37.5973V17.8235C20.4007 17.0537 21.234 16.5725 21.9007 16.9574L39.0253 26.8443Z" fill="white"/>
                                    </svg>
                                </div>
                            </div>
                            <p class="tl" v-html="$options.filters.subStrimpoNewsTitle(i.title)"></p>
                        </router-link>
                        </swiper-slide>
                    </swiper>
                </div>
            </div>
        </v-layout>

        <v-layout row>
            <div class="most">
                <div class="title">
                    <span><img :src="$vuetify.theme.dark ? '/assets/img/z-mohammed_dark.svg' : '/assets/img/z-mohammed.svg'" /></span>
                    <p class="tl">{{ $t("most_read") }}</p>
                </div>
                <div class="slider">
                    <template v-if="!mostLoad">
                    <swiper class="swiper dirr" ref="mySwiper" :options="swiperOptions2">
                        <swiper-slide v-for="i in mostNews" :key="i">
                            <router-link :to="'/new/'+ i.id + '?&lang=' +$i18n.locale" class="item" >
                                <div class="img">
                                    <img :src="'https://alkafeel.net/news/mcroped96/390/' + i.image" :alt="i.title" />
                                    <div class="icon" v-if="i.video">
                                        <svg width="54" height="55" viewBox="0 0 54 55" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="27.1089" cy="27.7103" r="26.8329" fill="#B1BD52" fill-opacity="0.8"/>
                                        <path d="M39.0253 26.8443C39.692 27.2292 39.692 28.1915 39.0253 28.5764L21.9007 38.4633C21.234 38.8482 20.4007 38.3671 20.4007 37.5973V17.8235C20.4007 17.0537 21.234 16.5725 21.9007 16.9574L39.0253 26.8443Z" fill="white"/>
                                        </svg>
                                    </div>
                                </div>
                                <p class="tl" v-html="$options.filters.subStrimpoNewsTitle(i.title)"></p>
                            </router-link>
                        </swiper-slide>
                        <div class="swiper-pagination swiper-pagination-bullets d-none d-sm-none d-md-flex" slot="pagination"></div>
                    </swiper>
                    </template>
                </div>
            </div>
        </v-layout>
        <div class="hidden-md-and-down">
            <news-search></news-search>
        </div>
    
    </div>
    
    </template>
    
    <style>
    /* .vjs_video_3-dimensions{
        width: 800px !important;
    height: 500px !important;
    } */
</style>
    <script>
        import searchVue from './search.vue';
    import videojs from 'video.js';
    import 'video.js/dist/video-js.min.css';
    import bbCodeParser from 'js-bbcode-parser';
    import { Fancybox} from "@fancyapps/ui";
    import '@fancyapps/ui/dist/fancybox/fancybox.css';
    import Swal from "sweetalert2";
    import '@videojs/themes/dist/fantasy/index.css';
    export default {
        name: "VideoPlayer",
        components: {
        'news-search': searchVue
    },
        data() {
            return {
                mainNews: [],
                mainimgs: [],
                social: [],
                date:'',
                commentsres : [],
                tagRes: null,
                relatedInnerRes: [],
                imgsPage : 1,
                commentsPage : 1,
                attached_imgs : 0,
                getMoreimg : true,
                getMorecomments : true,
                showcomments : true,
                MainNews_skeleton : true,
                sideNews_skeleton : true,
                loader: null,
                loading: false,
                loading2: false,
                relatedNews : [],
                mostLoad: true,
                mostNews : [],
                news_id : this.$route.params.id,
                comments:{name:'', email:'', text:'', id:''},
                swiperOptions1: {
                    slidesPerView: 'auto',
                    spaceBetween: 22,
                    freeMode: true,
                    keyboard: true,
                },
                swiperOptions2: {
                    slidesPerView: 'auto',
                    spaceBetween: 22,
                    freeMode: true,
                    keyboard: true,
                    pagination: {
                        el: '.swiper-pagination',
                        clickable: true,
                    },
                },
                valid: false,
                rules: {
                    required: value => !!value || this.$t("required_field"),
                    min: (v) => v.length >= 3 || this.$t("three_letters"),
                    email: v => !v || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || this.$t("alert_wrong_email"),
                },
                player: null,
                videoOptions: {
                    autoplay: true,
                    controls: true,
                    fill: true,
                    
                    //fluid: false,
                    sources: [{ src:'', type: "application/x-mpegURL"}],
                },
                // used for vue-meta
                title : '',
                Description: '',
                Image: '',
                mainPage: this.$t('index_news'),
                main_date: null
            }
        },
        metaInfo() {
            // let metaTitle = this._data.title;
            // let metaImage = 'https://alkafeel.net/ar-news/images/main/'+this._data.Image;
            // let metaDescription = this._data.Description;
            // console.log(this._data.mainNews[0].title1);
            // console.log(this.$metaInfo);
            // console.log(this);
            return { 
                title: this._data.title+' | '+this._data.mainPage,
                meta: [
                    { name: 'description', content:  this._data.Description.substring(0,120)},
                    { property: 'og:title', content: this._data.title},
                    { property: 'og:description', content: this._data.Description.substring(0,120)},
                    { property: 'og:image', content: 'https://alkafeel.net/ar-news/images/main/'+this._data.Image},
                ]
            }
        },
        computed: {
            //swiper() {return this.$refs.mySwiper.$swiper}
        },
        watch: {
            '$route' (to,from) {
                let main_id = to.params.id;
                if (main_id === 'index') {main_id = to.query.id; };
                this.mainimgs = [];
                this.imgsPage = 1;
                this.attached_imgs = 0;
                const state = { 'page_id': 1, 'user_id': 5 };
                const url = '/news/index?id='+main_id+'&lang='+this.$i18n.locale;
                // history.pushState(state, '', url);
                history.replaceState({}, "", url);
                if (this.$route.params.id === 'index') {this.news_id =  this.$route.query.id;}
                else {this.news_id =  this.$route.params.id;}
                document.title =this.$t("news");
                window.scrollTo(0, 0);
                this.grtmainNewsNews();
                // this.iniFancy();
                this.grtrelatedNews();
            },
            loader () {
                const l = this.loader
                this[l] = !this[l]
                setTimeout(() => (this[l] = false), 2000)
                this.loader = null
            },
        },
        mounted() {
            let main_id = this.$route.params.id;
            if (main_id === 'index') {main_id = this.$route.query.id; };
            const state = { 'page_id': 1, 'user_id': 5 };
            const url = '/news/index?id='+main_id+'&lang='+this.$i18n.locale;
            window.scrollTo(0, 0);
            // history.pushState(state, '', url);
            history.replaceState({}, "", url);
            //         const state = { 'page_id': 1, 'user_id': 5 }
            // const url = 'hello-world.html'
            //history.pushState(state, '', url);
            document.title =this.$t("news");
            this.iniFancy();
        },
        created () {
            if (this.$route.params.id === 'index') {this.news_id =  this.$route.query.id;}
            else {this.news_id =  this.$route.params.id;}
            this.grtsocial();
            this.grtcomments();
            
            this.grtmostNews();
            this.grtmainNewsNews();
        },
        methods:{
            grtmainNewsNews () {
                this.$axios.get("articles/GetById/" + this.news_id, {
                })
                .then(res => {
                    if (res.data.length === 0) {
                         // console.log(this.$route.query);
                         this.$router.push('/articles?lang=' + this.$i18n.locale)
                    }
                    else {
                        this.mainNews = res.data;
                        this.getRelatedInner();
                        
                        if (this.mainNews[0].video) {
                            this.main_date = this.mainNews[0].updated;
                        } else {
                            this.main_date = this.mainNews[0].time;
                        }
                        
                        const date = new Date(this.main_date*1000);

                        this.date=new Intl.DateTimeFormat(this.$i18n.locale+'-GB', { dateStyle: 'full', timeStyle: 'long' }).format(date);
                        this.grtmainNewsimgs(this.news_id);
                        this.grtrelatedNews(res.data[0].cat_req);
                        this.title = res.data[0].title1;
                        this.Description = res.data[0].text;
                        this.Image = res.data[0].image;
                        // document.title = this.title + ' | ' + this.$t("index_news");
                        this.grtsocial();
                        this.getTags();
                        this.grtcomments();
                        // this.iniFancy();
                        this.grtmostNews();
                        setTimeout(() => this.MainNews_skeleton = false, 500);
                        if (res.data[0].video) {
                            this.videoOptions.sources[0].src = "https://static1.alkafeel.net/videos/"+res.data[0]['video']+"/"+res.data[0]['video']+".m3u8";
                            setTimeout(() => this.play_player(), 500);
                        }
                        // console.log(res.data);
                    }
                })
                .catch(() => {
                });
            },
            play_player () {
                this.player = videojs(this.$refs.videoPlayer, this.videoOptions, function onPlayerReady() {})
            },
            grtmainNewsimgs (x) {
                //this.$axios.get("articles/AttachedImages/" + this.news_id + '?page=' + this.imgsPage, {
                this.$axios.get("articles/AttachedImages/" + this.news_id, {
                })
                .then(res => {
                    this.imgsPage ++ ;
                    // this.mainimgs = res.data.data;
                    this.mainimgs = res.data.data.map(i => ({
                        ...i, 
                        full_url: `https://alkafeel.net/news/images/attach/${i.news_id}-${i.img}.${i.ext}`, 
                        thumb_url: `https://alkafeel.net/news/images/attach/resized/${i.news_id}-${i.img}.${i.ext}` 
                    }));
                    //this.mainimgs = this.mainimgs.concat(res.data.data);
                    //if (res.data.data.length < 7) {this.getMoreimg = false;}
                    //else {this.attached_imgs = ((this.attached_imgs == 0) ? res.data.total - 7 : this.attached_imgs -7);}
                    //console.log(res.data.data.length);
                    //console.log(res.data.data);
                })
                .catch(() => {
                });
            },
            // iniFancy () {
            //     Fancybox.bind('[data-fancybox="gallery"]', {
            //         dragToClose: false,
            //         Toolbar: false,
            //         closeButton: "top",
            //         Image: {
            //         zoom: false,
            //         },
            //         on: {
            //             initCarousel: (fancybox) => {
            //             const slide = fancybox.Carousel.slides[fancybox.Carousel.page];
            //             fancybox.$container.style.setProperty("--bg-image",`url("${slide.src}")`);
            //             },
            //             "Carousel.change": (fancybox, carousel, to, from) => {
            //             const slide = carousel.slides[to];
            //             fancybox.$container.style.setProperty("--bg-image", `url("${slide.src}")`);
            //             },
            //         },
            //     });
            // },
            iniFancy () {
                Fancybox.bind('[data-fancybox="gallery"]', {
                    Hash: false,
                    placeFocusBack: false,
                    dragToClose: false,
                    Toolbar: false,
                    Carousel: {
                    center: true, 
                    friction: 0,   
                    Panzoom: {
                        touch: false, 
                    },
                    },
                    Zoom: false, 
                    Thumbs: {
                        autoStart: true,
                        axis: "x",
                        type: "classic" 
                    },
                    keyboard: {
                        Escape: "close",
                        ArrowRight: "prev",
                        ArrowLeft: "next",
                    },
                    Image: {
                        zoom: false,
                        click: "close",
                    },
                    
                });
            },
            grtrelatedNews (x) {
                //setTimeout(() => this.sideNews_skeleton = false, 500);
                this.$axios.get("articles/getByCat/" + x + "/5", {
                })
                .then(res => {
                    this.relatedNews = res.data.data;
                    setTimeout(() => this.sideNews_skeleton = false, 500);
                    //console.log(res.data.data);
                })
                .catch(() => {
                });
            },
            grtmostNews () {
                this.$axios.get("articles/GetMostViewed/6", {
                })
                .then(res => {
                    this.mostNews = res.data;
                    this.mostLoad = false;
                    //console.log(res.data);
                })
                .catch(() => {
                });
            },
            grtsocial () {
                this.$axios.get("articles/GetSocialCount", {})
                .then(res => {
                    this.social = res.data;
                    //console.log(res.data);
                })
                .catch(() => {
                });
            },
            getTags () {
                this.$axios.get("articles/GetTagsByArtId/" + this.news_id, {})
                .then(res => {
                    this.tagRes = res.data;
                    //console.log(res.data);
                })
                .catch(() => {
                });
            },
            getRelatedInner () {
                this.$axios.get("articles/GetRelatedArt/" + this.news_id, {})
                .then(res => {
                    this.relatedInnerRes = res.data.data;
                    //console.log(res.data.data);
                })
                .catch(() => {
                });
            },
            reCreateText (x,y) {
                if (x.includes('relatedinner')) {
                    var txx =  x.split('relatedinner');
                    if (y == 1) {return this.bbcode(txx[0].toString());}
                    else {return this.bbcode(txx[1].toString());}
                }
                else {this.relatedInnerRes = [];}
            },
            grtcomments () {
                this.loader = 'loading';
                this.$axios.get("articles/comments/" + this.news_id + '?page=' + this.commentsPage, {
                })
                .then(res => {
                    this.commentsres = this.commentsres.concat(res.data.data);
                    if (res.data.data.length == 0 && this.commentsPage == 1) {this.showcomments = false;}
                    if (res.data.data.length < 4) {this.getMorecomments = false;}
                    this.commentsPage ++ ;
                    //console.log(this.news_id);
                })
                .catch(() => {
                });
            },
            comment_send () {
                if (this.$refs.form.validate()) {
                    var formdata = new FormData();
                    formdata.append("name",  this.comments.name);
                    formdata.append("id",  this.news_id);
                    formdata.append("text", this.comments.comment);
                    if (this.comments.email != '') { formdata.append("email", this.comments.email);}
                    this.loader = 'loading2';
                    this.$axios({method: 'post',url: 'https://alkafeel.net/alkafeel_back_test/api/v1/articles/sendComment',data: formdata,
                    }).then(res => {
                        Swal.fire({
                            title: this.$t("comment_sent_successfully"),
                            text: "",
                            icon: "success",
                            timer: 3000,
                            confirmButtonColor: '#B1BD52',
                        });
                        this.comments = {};
                    }).catch(() => {
                    });
                }
            },
            bbcode (x) {
                return bbCodeParser.parse(this.stripHTML(x.replace('relatedinner','')));
            },
            stripHTML(value) {
                if (!value) return '';

                const div = document.createElement('div');
                div.innerHTML = value;

                const allowedTags = ['TABLE', 'TR', 'TD', 'TH', 'TBODY', 'THEAD', 'TFOOT'];

                div.querySelectorAll('*').forEach(el => {
                    if (!allowedTags.includes(el.tagName)) {
                        el.replaceWith(document.createTextNode(el.innerText)); 
                    }
                });

                return div.innerHTML; // Return sanitized content
            },
            // stripHTML (value) {
            //     const div = document.createElement('div')
            //     div.innerHTML = value
            //     const text = div.textContent || div.innerText || ''
            //     return text
            //     // return value.replace(/(<\/?(?:a|ol|ul|li|table|colgroup|col|tr|th|td|tbody|em|strong|s|span|img)[^>]*>)|<[^>]+>/ig, '$1');
            // },
            subStr (x) {
                if (x != null) {return x.substring(0, 70) + '...';}
            },
        },
        filters: {
            subStrmostNewsitlexx: function (string) {if (string != null) {return string.substring(0, 250) + '...';}},
            subStrlastNewsitle: function (string) {if (string != null) {return string.substring(0, 150) + '...';}},
            subStrmostNewsitle: function (string) {if (string != null) {return string.substring(0, 50) + '...';}},
            subStrimpoNewsTitle: function (string) {if (string != null) {return string.substring(0, 85) + '...';}},
            FormatDate: function (unixTime) {
                let d = new Date(unixTime*1000);
                let ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(d);
                let mo = new Intl.DateTimeFormat('en', { month: '2-digit' }).format(d);
                let da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(d);
                return (`${da}-${mo}-${ye}`);
            },
             FormatDatex: function (unixTime) {
                let d = new Date(unixTime*1000);
                let ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(d);
                let mo = new Intl.DateTimeFormat('en', { month: '2-digit' }).format(d);
                let da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(d);
                return (`${da}-${mo}-${ye}`);
            },
        },
        beforeDestroy() {
            Fancybox.destroy();
        },
    }
    </script>
<style>
.f-thumbs__track {
    transform: translate3d(0px, 0px, 0px) scale(1)!important;
    flex-flow: row-reverse wrap-reverse !important;
}
.fancybox__nav .is-next {
    left: 40px!important;
    right: auto; 
    transform: rotate(180deg)!important;
}
.fancybox__nav .is-prev {
    right: 40px !important;
    left: auto!important;
    transform: rotate(180deg)!important;
}
</style>
    <style scoped>
    .img_title {
        color: white;
        font-size: 16px;
        font-weight: 400;
        position: absolute;
        bottom: 10px;
        left:0;
        right:0;
        text-align: center;
        text-shadow: 1px 1px 2px #000;
    }
    .gradiant_img {
        background-image: linear-gradient(to top, #0C0F12 0%, transparent 72px);
    }
    </style>
    <style>
    .news_inner .mainNews .tx img, .news_inner .mainNews .txx img {width: 100%;}
    .news_inner .mainNews .tx, .news_inner .mainNews .txx{line-height: 1.9 !important;}
    
    </style>
    